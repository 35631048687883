import { useEffect, useState, useCallback } from "react";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { BsCheckLg } from "react-icons/bs";
import { useForm } from "react-hook-form";
import notification from "../helpers/notification";
import PageHeader from "../components/common/PageHeader";
import ApiService from "../api/ApiService";
import Tooltip from "@mui/material/Tooltip";

const BhagavadGitaRegistrationForm = () => {
    const [top, setTop] = useState(0);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false); // New state variable
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
        setValue,
        getValues,
        control,
    } = useForm({
        defaultValues: {
            state: "",
            city: "",
        },
    });

    const formRef = useCallback((node) => {
        if (node) {
            setTop(node.offsetTop);
        }
    });

    const handleGitaClass = async (data) => {
        console.log("data", data);
        setLoading(true);
        try {
            await ApiService.saveGitaClass(data);
            notification("success", "Hare Krishna! Form submitted successfully.");
            setSubmitted(true); // Set submitted to true upon successful submission
            reset();
        } catch (error) {
            console.error("Error submitting data:", error);
            notification("error", "Error submitting form. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <MetaData title="Registration Form - Bhagavad Gita Course" />
            <PageHeader title="Registration Form" imgSrc="/images/page-header/17.webp" />
            <section className="donation-form-sec" ref={formRef}>
                <div className="container">
                    <div className="row d-flex justify-content-center mb-5" id="scrollToDonateFrom">
                        <div className="col-md-10">
                            <div id="scrollToDonationForm" className="heading">
                                <h2 className="head-1">Bhagavad Gita Class</h2>
                                <p className="b-line" style={{ textAlign: 'center' }}>
                                    Registration Form for 6 Months Certificate Course on Bhagavad Gita
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="form-wrap my-5" style={{ scrollMarginBlockStart: "150px" }}>
                        {submitted ? (
                            <div className="thank-you-message text-center">
                                <h2>Thank you for the registration.</h2>
                                <p>Our volunteers will contact you soon.</p>
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit(handleGitaClass)}>
                                <div className="row align-items-start">
                                    <div className="col-lg-4 pe-xl-4">
                                        <img
                                            src="/images/bhagavad-gita/bhagwatgita-class.jpeg"
                                            alt="Bhagavad Gita"
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="col-lg-8 mt-lg-0 mt-4 ">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-part">
                                                    <label htmlFor="">Please mention your child's name *</label>
                                                    <input
                                                        type="text"
                                                        {...register("name", {
                                                            required: true,
                                                        })}
                                                        placeholder="Name"
                                                    />
                                                    {errors.name?.type === "required" && (
                                                        <small className="text-danger error">This is required</small>
                                                    )}
                                                    {errors.name?.message && (
                                                        <small className="text-danger error">{errors.name?.message}</small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-part">
                                                    <label htmlFor="">Please mention your whatsApp number*</label>
                                                    <input
                                                        type="tel"
                                                        placeholder="WhatsApp Number"
                                                        maxLength={10}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register("phone", {
                                                            required: true,
                                                            pattern: {
                                                                value: /^[6-9]\d*$/,
                                                                message: "Please provide valid WhatsApp Number",
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: "WhatsApp Number must be minimum 10 digit",
                                                            },
                                                        })}
                                                    />
                                                    {errors.phone?.type === "required" && (
                                                        <small className="text-danger error">This is required</small>
                                                    )}
                                                    {errors.phone && (
                                                        <small className="text-danger error">{errors.phone.message}</small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-part">
                                                    <label htmlFor="">Please mention your address *</label>
                                                    <Tooltip title="For Prasadam please provide this" placement="bottom-start">
                                                        <textarea
                                                            {...register("address", {
                                                                required: true,
                                                                minLength: {
                                                                    value: 10,
                                                                    message: "Address must be minimum 10 characters",
                                                                },
                                                            })}
                                                            placeholder="Address"
                                                        ></textarea>
                                                    </Tooltip>
                                                    {errors.address?.type === "required" && (
                                                        <small className="text-danger error">This is required</small>
                                                    )}
                                                    {errors.address?.message && (
                                                        <small className="text-danger error">{errors.address?.message}</small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-part">
                                                    <label htmlFor="">Please mention your city of residence *</label>
                                                    <input
                                                        type="text"
                                                        {...register("cityname", {
                                                            required: true,
                                                        })}
                                                        placeholder="City"
                                                    />
                                                    {errors.cityname?.type === "required" && (
                                                        <small className="text-danger error">This is required</small>
                                                    )}
                                                    {errors.cityname?.message && (
                                                        <small className="text-danger error">{errors.cityname?.message}</small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-part">
                                                    <label htmlFor="age">Please mention your child's age *</label>
                                                    <input
                                                        type="tel"
                                                        placeholder="Age"
                                                        {...register("age", {
                                                            required: "This is required",
                                                        })}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    {errors.age && (
                                                        <small className="text-danger error">{errors.age.message}</small>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-part">
                                                    <label htmlFor="attendingClasses">
                                                        Is your child attending our regular classes? *

                                                    </label>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <input
                                                                type="radio"
                                                                id="attendingClassesYes"
                                                                value="yes"
                                                                {...register("attendingClasses", { required: "This is required" })}
                                                            />
                                                            <label htmlFor="attendingClassesYes">Yes</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <input
                                                                type="radio"
                                                                id="attendingClassesNo"
                                                                value="no"
                                                                {...register("attendingClasses", { required: "This is required" })}
                                                            />
                                                            <label htmlFor="attendingClassesNo">No</label>
                                                        </div>
                                                    </div>
                                                    {errors.attendingClasses && (
                                                        <small className="text-danger error">
                                                            {errors.attendingClasses.message}
                                                        </small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-part">
                                                    <label htmlFor="">
                                                        Would like to hear from you about your expectations from this course
                                                    </label>
                                                    <textarea
                                                        {...register("expectations", {
                                                            minLength: {
                                                                value: 10,
                                                                message: "Please enter at least 10 characters",
                                                            },
                                                        })}
                                                        placeholder="Share your expectations here..."
                                                    ></textarea>
                                                    {errors.expectations?.type === "minLength" && (
                                                        <small className="text-danger error">
                                                            {errors.expectations.message}
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-part">
                                                    <label htmlFor="">Feedback on Ongoing Classes</label>
                                                    <textarea
                                                        {...register("feedback", {
                                                            minLength: {
                                                                value: 10,
                                                                message:
                                                                    "Please provide detailed feedback (minimum 10 characters)",
                                                            },
                                                        })}
                                                        placeholder="Share your feedback here..."
                                                    ></textarea>
                                                    {errors.feedback?.type === "minLength" && (
                                                        <small className="text-danger error">{errors.feedback.message}</small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-12 mt-4">
                                                <button
                                                    type="submit"
                                                    className="box-hover custom-btn-cls ms-0"
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <div className="d-flex align-items-center">
                                                            <div className="spinner-border text-light" role="status"></div>
                                                            <div className="ms-2">SUBMITTING...</div>
                                                        </div>
                                                    ) : (
                                                        <>SUBMIT</>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </section>
            <GoToTop top={top} />
        </>
    );
};

export default BhagavadGitaRegistrationForm;
