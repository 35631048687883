import PageHeader from "../components/common/PageHeader";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { useForm, useFieldArray } from "react-hook-form";
import ApiService from "../api/ApiService";
import notification from "../helpers/notification";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import AboutYatras from '../components/yatras/AboutYatras';
import YatrasCardWrap from '../components/yatras/YatrasCardWrap.jsx';
import moment from 'moment';
import { MdClose } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";


import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

var preRegisterYatras = [
  {
    title: "Sri Dham|Mayapur",
    image: "/images/yatras/mayapur-card-min_1.webp",
    description:
      "Join us for a life-changing experience to the birthplace of Lord Chaitanya Mahaprabhu and the spiritual headquarters of the Hare Krishna Movement.",
  },
  {
    title: "Jagannath|Puri",
    image: "/images/yatras/jagannath-card-min.webp",
    description:
      "Join us as we make way to the courtyard of the Lord of the Universe, Puri where Lord Jagannath, his brother Balbhadra and sister Subhadra reside. ",
    register: "0",
  },
  {
    title: "Tirupati|Balaji",
    image: "/images/yatras/tirupati-balaji-card-min_1.webp",
    description:
      "Join us to pay obeisances to Lord Venkateswara at the Tirupati Balaji temple in the Tirumala Hills of Andhra Pradesh.",
    register: "0",
  },
  {
    title: "Kartik|Yatra",
    image: "/images/yatras/kartik-yatra-card-min-2.webp",
    description:
      "ISKCON of Bhiwandi celebrates the holy month of Kartik by organising a special Yatra  for devotees that helps deepen their connection with Hari. ",
    register: "0",
  },
];


const expiry = process.env.REACT_APP_OTPEXPIRY;
let timer = null;

const Yatras = () => {
  const { setUser, setToken, user } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const recaptchaRef = useRef();
  const [loginPopup, setLoginPopup] = useState(false);

  if (user) {
    localStorage.removeItem("token");
    setUser(null);
    setToken(null);
    navigate("/yatras");
    localStorage.removeItem('icsmob');
    localStorage.removeItem('ics_mob');
    localStorage.removeItem('ics_id');
    localStorage.removeItem('multiplesevatype');
    localStorage.removeItem('multiplesevaPrice');
    localStorage.removeItem('multiplesevaName');
    localStorage.removeItem('ics_number')

    localStorage.removeItem('ics_all_data');

  }




  const [show, setShow] = useState(false);
  const [selectedYatra, setSelectedyatra] = useState(null);
  const [pric, setPrice] = useState(null);
  const [TotalPrice, setTotalPrice] = useState(null);
  const [YatraPrice, setYatraPrice] = useState(null);
  const [noOfDays, setDays] = useState(null);
  const [Occupancy, setOccupancy] = useState(null);
  const [People, setPeople] = useState(null);
  const [pay_id, setPaymentid] = useState(null);
  const [price, setFinal] = useState('0');
  const [part_pay, setPartPay] = useState('fullpay');
  const [errorMinPrice, setErrorMinPrice] = useState(null);
  const [handelrooms, setHandelrooms] = useState(null);
  const [displayrooms, setDisplayrooms] = useState(0);
  const [displaychild, setDisplaychild] = useState(1);
  const [noOfrooms, setHandleNoOfrooms] = useState(0);
  const [noofchild, setHandleChild] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [disabledBtnn, setDisabledBtnn] = useState(false);

  const [stay_price, setHandelStay] = useState(null);


  const [leftamount, setLeftAmount] = useState(0);
  const [allDisabled, setAllDisabled] = useState(false);



  const [preacherName, fetchSetPreacherName] = useState('');

  const [fetch_day, fetchSetdays] = useState(0);
  const [fetch_adult, fetchSetadults] = useState(0);

  const [fetch_child, fetchSetChild] = useState(0);
  const [fetch_name, fetchSetname] = useState(0);
  const [fetch_rooms, fetchSetRooms] = useState(0);
  const [fetch_stay, fetchSetstay] = useState([]);
  const [fetch_occupancy, fetchSetOccupancy] = useState(null);
  const [fetch_totalamount, fetchSetTotalAmount] = useState(0);

  const [fetch_paidamount, fetchSetPaidAmount] = useState(0);

  const [yatra_id, setYatraid] = useState(0);

  const { fields, append, remove } = useFieldArray({ control, name: "relatives" });

  const [FoodAndTran, setFoodAndTran] = useState(0);
  const [Accomodation, setAccomodation] = useState(0);


  const [defaultFields] = useState([
    { inputName: "relativeName", type: "adult", label: "Adult Name" },
    { inputName: "children", type: "child", label: "Children Name" }
  ]);
  //console.log("fields", fields)

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePreRegister = (yatra) => {
    setSelectedyatra(yatra);
    setShow(true);
    reset();
    setInputValue('');
  };
  const handleModalHide = () => {
    setShow(false);
    setPartPay('fullpay')
    setAllDisabled(false);
    setLeftAmount(0)

    setYatraid('')
    setPartPay('')

    setLeftAmount('');

    fetchSetdays('')
    fetchSetname('')


    fetchSetadults('')
    fetchSetstay('')

    fetchSetChild('')
    fetchSetRooms('')

    fetchSetOccupancy('')
    fetchSetTotalAmount('')
    fetchSetPreacherName('');
    fetchSetPaidAmount('')
    reset({
      name: '',
      email: '',
      location: '',
      age: '',
      gender: '',
      preacherName: '',
      phone: '',
    });
  };



  let Childcost = 0

  Childcost = Math.round(500 * noofchild * noOfDays)

  // console.log(noOfrooms)
  // console.log(stay_price)
  // console.log(noOfDays)

  // console.log(Occupancy)

  //const handleCalculation = () => {
  let accomodation = 0

  if (Occupancy === 'Personal') {
    accomodation = Math.round(stay_price * noOfDays * noOfrooms)
  } else if (Occupancy === 'Double') {
    accomodation = Math.round(noOfrooms * noOfDays * stay_price / 2)
  } else if (Occupancy === 'Tripple') {
    accomodation = Math.round(noOfrooms * noOfDays * stay_price / 3)
  } else if (Occupancy === 'Quadruple') {
    accomodation = Math.round(noOfrooms * noOfDays * stay_price / 4)
  }


  let food = 0

  food = Math.round(1000 * noOfDays * People)



  const totalPrice = accomodation + food + Childcost

  //console.log(accomodation)


  //console.log(food+'--'+accomodation+'----'+Childcost)
  // console.log(Childcost)

  // }



  const handleDays = (e) => {
    setDays(e.target.value);
    // setHandleChild('');
    //   setPeople(''); 
  };


  const handleOccupancy = (e) => {

    setOccupancy(e.target.value);

    setHandleChild('');
    setPeople('');
    setDays('');
    setHandleNoOfrooms('');

  };


  const handlePeople = (e) => {
    setPeople(e.target.value);
    // setHandleChild('');
    //  setFinal(e.target.value * pric)
  };

  const handleNoOfrooms = (e) => {
    setHandleNoOfrooms(e.target.value);
    // setHandleChild('');
    //  setPeople(''); 
    //  setDays('');
  }

  const handleChild = (e) => {
    setHandleChild(e.target.value);
    //handleCalculation()
  }


  const handleStay = (e) => {
    setHandelStay(e.target.value);
    // console.log(e.target.value);
    if (e.target.value > 0) {

      setDisabledBtnn(false)
    } else {
      setDisabledBtnn(true)
    }
    setHandleNoOfrooms('');
    setHandleChild('');
    setPeople('');
    setDays('');
  }

  // console.log(stay_price)

  const onFormSubmitt = async (data) => {
    // return console.log(data);
    try {
      const token = await recaptchaRef.current.executeAsync();
      // console.log(token)
      data.token = token;

      const pay_idd = localStorage.getItem('my-key');

      let stay = '';

      if (stay_price === '0') {
        stay = 'independent';
      } else if (stay_price === '1200') {
        stay = selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ? 'AC' : 'Dhanuka Ashram (AC)';
      } else if (stay_price === '1000') {
        stay = selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ? 'NON AC' : 'Padmini Ashram (Non AC)';
      } else if (stay_price === '3000') {
        stay = selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ? '' : 'Padmini Ashram (AC)';
      }

      console.log(stay);

      const res = await ApiService.createYatraEnquiry({
        yatraName:
          selectedYatra?.title.split("|")[0] +
          " " +
          selectedYatra?.title.split("|")[1],
        ...data,
        noOfAccompanyingPeople: People,
        noOfDays: noOfDays,
        Occupancy: Occupancy,
        stay: stay,
        noOfRooms: noOfrooms,
        noOfChild: noofchild,
        TotalAmount: 1000,
        //PaidAmount: part_pay === 'fullpay' ? totalPrice : data.partPayment,
        PaidAmount: 1000,
        Payment_id: pay_idd,
      });


      setShow(false);
      reset();
      notification("success", "Form submitted successfully!");
      recaptchaRef.current.reset();
      localStorage.removeItem('my-key');
      setHandleChild('');
      setPeople('');
      setTotalPrice('');
      const yatraTitle = selectedYatra?.title.split("|")[0];
      navigate('/yatra-thank-you-page', { state: { yatraTitle } });
      // window.location.reload();

    } catch (error) {
      console.log(error);
      notification('error', error.response.data.message)
    }
  };

  console.log(noofchild)

  const handleRooms = (e) => {
    setHandelrooms(e.target.value);
  }

  //  console.log(totalPrice)


  const onFormSubmit = async (data) => {
    //return console.log(data);

    let partAmount = 0

    if (leftamount > 0) {
      partAmount = leftamount
    } else {
      //partAmount = data?.partPayment
      partAmount = 1000
    }

    const res = await ApiService.createYatraEnquiry({
      ...data,
      amount: partAmount,
    });

    //console.log(res)

    try {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        currency: res.currency,
        amount: res.amount,
        name: process.env.REACT_APP_PROJECT_NAME,
        description: "Help Us to Help the Society",
        image: `${process.env.REACT_APP_S3_URL}/assets/img/logo.png`,
        order_id: res.id,
        handler: function (response) {

          if (leftamount > 0) {
            notification("success", "Payment Completed");
            const yatraTitle = selectedYatra?.title.split("|")[0];
            navigate('/yatra-thank-you-page', { state: { yatraTitle, pay: 'complete' } });
            fetchYatraEnqUpdate(yatra_id)
            setShow(false);
            setAllDisabled(false);
            setTotalPrice(0);
            reset({
              name: "",
              phone: "",
              email: "",
              location: "",
              age: "",
              gender: "",
              preacherName: "",
            });
          } else {

            notification("success", "Payment successfull!");

            setPaymentid(response.razorpay_payment_id)
            const textForStorage = response.razorpay_payment_id
            localStorage.setItem('my-key', textForStorage);
            const textFromStorage = localStorage.getItem('my-key');
            if (textFromStorage) {
              onFormSubmitt(data);
            }
          }

        },
        prefill: {
          name: data?.name,
          email: data?.email,
          contact: data?.phone,
        },
        notes: {
          type: "donation",
        },
        theme: {
          color: "#FFD370",
        },
        modal: {
          ondismiss: async function () {
            const cancelDonation = await ApiService.cancelDonation(res.id);

          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

    } catch (error) {
      console.log(error);
      notification('error', error.response.data.message)
    }


  };

  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    // if (user) {
    //   reset({
    //     name: user.name,
    //     phone: user.phone,
    //     email: user.email,
    //     address: user.address,
    //   });
    // }
  }, [user]);

  useEffect(() => {
    fetchMeta("yatras");
  }, []);



  const [Yatras, setYatras] = useState([])
  const fetchYatras = async () => {
    try {
      const res = await ApiService.fetchYatras()
      console.log(res)
      setYatras(res)
    } catch (error) {
      console.log(error)
    }
  }


  // const fetchYatraEnq = async (data) => {
  //   try {
  //     const res = await ApiService.fetchYatraEnq(data)
  //    console.log('====================================',res.filter(dat => dat.yatraName.replace(/undefined/g, '') === selectedYatra.title))
  //     console.log('fetch----------',res)
  //     if (res.yatraName.replace(/undefined/g, '') === selectedYatra?.title ) {


  //       let relativeName = '';
  //       let children = '';
  //       let relativeNameCount = 0;
  //       let childrenCount = 0;

  //       // Iterate through noOfAccompanyingPeople and extract relativeName and children
  //       res[0].noOfAccompanyingPeople.forEach(person => {
  //         if (person.relativeName) {
  //           relativeName += relativeName ? `, ${person.relativeName}` : person.relativeName;
  //           relativeNameCount++;
  //         }
  //         if (person.children) {
  //           children += children ? `, ${person.children}` : person.children;
  //           childrenCount++;
  //         }
  //       });



  //       setYatraid(res[0]._id)
  //       setAllDisabled(true)
  //       setPartPay('partpay')
  //       setLeftAmount(res[0].RemainingAmount)

  //       setFoodAndTran(res[0].foodTransportCharge)
  //       setAccomodation(res[0].accomodationCharge)

  //       //setLeftAmount(2500);

  //       fetchSetdays(res[0].noOfDays)
  //       fetchSetname(res[0].name)
  //       fetchSetadults(relativeNameCount)
  //     //  fetchSetadults('')
  //       fetchSetstay(res[0].stay)

  //       fetchSetChild(childrenCount) 

  //       //fetchSetInfant(res[0].noOfChild > 0 ? res[0].noOfChild : 0)
  //       fetchSetRooms(res[0].noOfRooms > 0 ? res[0].noOfRooms : 0)

  //       fetchSetOccupancy(res[0].Occupancy)
  //       fetchSetTotalAmount(res[0].TotalAmount)

  //       fetchSetPaidAmount(res[0].PaidAmount)
  //       reset({
  //         name: res[0].name,
  //         email: res[0].email,
  //         location: res[0].location,
  //         age: res[0].age,
  //         gender: res[0].gender,
  //         preacherName: res[0].preacherName,
  //       });



  //     } else {

  //       setAllDisabled(false)
  //       setPartPay('fullpay')
  //       setLeftAmount(0)
  //       reset({
  //         name: user.name,
  //         email: user.email,
  //         phone: user.phone,
  //         location: "",
  //         age: "",
  //         gender: "",
  //         preacherName: "",
  //       });

  //     }
  //     //setYatras(res)   
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const fetchYatraEnq = async (data) => {
    try {
      const res = await ApiService.fetchYatraEnq(data);
      console.log('fetch----------', res);

      // Assuming selectedYatra is defined in your component's state or props
      // const selectedYatra = { title: "Dakor Dham Yatra" }; // Replace this with your actual selectedYatra

      // Ensure res is an array before proceeding
      if (Array.isArray(res)) {
        const filteredResults = res.filter(dat => {
          const yatraName = dat.yatraName ? dat.yatraName.replace(/undefined/g, '') : '';
          return yatraName === selectedYatra.title;
        });
        console.log('====================================', filteredResults);

        if (filteredResults.length > 0) {
          const result = filteredResults[0];

          let relativeName = '';
          let children = '';
          let relativeNameCount = 0;
          let childrenCount = 0;
          console.log('preacher -------', result.preacherName);

          // Iterate through noOfAccompanyingPeople and extract relativeName and children
          result.noOfAccompanyingPeople.forEach(person => {
            if (person.relativeName) {
              relativeName += relativeName ? `, ${person.relativeName}` : person.relativeName;
              relativeNameCount++;
            }
            if (person.children) {
              children += children ? `, ${person.children}` : person.children;
              childrenCount++;
            }
          });

          setYatraid(result._id);
          setAllDisabled(true);
          setPartPay('partpay');
          setLeftAmount(result.RemainingAmount);

          setFoodAndTran(result.foodTransportCharge);
          setAccomodation(result.accomodationCharge);

          fetchSetdays(result.noOfDays);
          fetchSetname(result.name);
          fetchSetadults( relativeNameCount > 0 ? parseFloat(relativeNameCount + 1) : relativeNameCount);
          fetchSetstay(result.stay);
          fetchSetChild(childrenCount);
          fetchSetRooms(result.noOfRooms > 0 ? result.noOfRooms : 0);
          fetchSetOccupancy(result.Occupancy);
          fetchSetTotalAmount(result.TotalAmount);
          fetchSetPaidAmount(result.PaidAmount);
          fetchSetPreacherName(result.preacherName);
          reset({
            name: result.name,
            email: result.email,
            location: result.location,
            age: result.age,
            gender: result.gender,
            //preacherName: result.preacherName,
          });
        } else {
          // No match found for the selected yatra
          setAllDisabled(false);
          setPartPay('fullpay');
          setLeftAmount(0);
          reset({
            name: user.name,
            email: user.email,
            phone: user.phone,
            location: "",
            age: "",
            gender: "",
            preacherName: "",
          });
        }
      } else {
        console.error("res is not an array. Actual value:", res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const fetchYatraEnq = async (data) => {
  //   try {
  //     const res = await ApiService.fetchYatraEnq(data);
  //     console.log('fetch----------', res);

  //     // Assuming selectedYatra is defined in your component's state or props
  //     const selectedYatra = { title: "Dakor Dham Yatra" }; // Replace this with your actual selectedYatra

  //     // Ensure res is an array before proceeding
  //     if (Array.isArray(res)) {
  //       const filteredResults = res.filter(dat => {
  //         const yatraName = dat.yatraName ? dat.yatraName.replace(/undefined/g, '') : '';
  //         console.log('-----------------------------', yatraName, selectedYatra.title);
  //         return yatraName === selectedYatra.title;
  //       });
  //       console.log('-----------------------------', filteredResults);

  //       if (filteredResults.length > 0) {
  //         const result = filteredResults[0];

  //         let relativeName = '';
  //         let children = '';
  //         let relativeNameCount = 0;
  //         let childrenCount = 0;
  //         console.log('fetchhhh -------', result);

  //         // Iterate through noOfAccompanyingPeople and extract relativeName and children
  //         result.noOfAccompanyingPeople.forEach(person => {
  //           if (person.relativeName) {
  //             relativeName += relativeName ? `, ${person.relativeName}` : person.relativeName;
  //             relativeNameCount++;
  //           }
  //           if (person.children) {
  //             children += children ? `, ${person.children}` : person.children;
  //             childrenCount++;
  //           }
  //         });

  //         setYatraid(result._id);
  //         setAllDisabled(true);
  //         setPartPay('partpay');
  //         setLeftAmount(result.RemainingAmount);

  //         setFoodAndTran(result.foodTransportCharge);
  //         setAccomodation(result.accomodationCharge);

  //         fetchSetdays(result.noOfDays);
  //         fetchSetname(result.name);
  //         fetchSetadults(relativeNameCount);
  //         fetchSetstay(result.stay);
  //         fetchSetChild(childrenCount);
  //         fetchSetRooms(result.noOfRooms > 0 ? result.noOfRooms : 0);
  //         fetchSetOccupancy(result.Occupancy);
  //         fetchSetTotalAmount(result.TotalAmount);
  //         fetchSetPaidAmount(result.PaidAmount);
  //         reset({
  //           name: result.name,
  //           email: result.email,
  //           location: result.location,
  //           age: result.age,
  //           gender: result.gender,
  //           preacherName: result.preacherName,
  //         });
  //       } else {
  //         // No match found for the selected yatra
  //         setAllDisabled(false);
  //         setPartPay('fullpay');
  //         setLeftAmount(0);
  //         reset({
  //           name: user.name,
  //           email: user.email,
  //           phone: user.phone,
  //           location: "",
  //           age: "",
  //           gender: "",
  //           preacherName: "",
  //         });
  //       }
  //     } else {
  //       console.error("res is not an array. Actual value:", res);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };


  const fetchYatraEnqUpdate = async (data) => {
    try {
      let AllData = {
        id: yatra_id,
        amt: fetch_totalamount,
        balance: leftamount
      }
      const res = await ApiService.fetchYatraEnqUpdate(AllData)
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }








  useEffect(() => {
    fetchYatras();
    fetchYatraEnq(user?.phone)
  }, [])


  const handelPartPay = (e) => {
    setPartPay(e.target.value)

  }

  const handleMinPriceKeyUp = (e) => {
    if (e.target.value < selectedYatra.minPrice) {
      setDisabledBtn(true)
      setErrorMinPrice('Please Enter Minimum Amount Rs.' + selectedYatra.minPrice);
    } else {
      setDisabledBtn(false)
      setErrorMinPrice('');
    }


  }


  const PhoneCheckk = async (e) => {
    const val = e.target.value.length;

    if (val === 10) {
      const res = await ApiService.fetchYatraEnq(e.target.value);
      console.log('fetch----------', res);

      // Assuming selectedYatra is defined in your component's state or props
      //const selectedYatra = { title: "Dakor Dham Yatra" }; // Replace this with your actual selectedYatra

      // Ensure res is an array before proceeding
      if (Array.isArray(res)) {
        const filteredResults = res.filter(dat => {
          const yatraName = dat.yatraName ? dat.yatraName.replace(/undefined/g, '') : '';
          return yatraName === selectedYatra.title;
        });
        console.log('====================================', filteredResults);
        if (filteredResults.length > 0) {
          //alert('number allready registered');
          notification('warning', 'This number is already in use. Please try with a different number.')
          reset({ phone: "" });
        } else {

        }
      }
    }

  }


  const PhoneCheck = (e) => {
    const val = e.target.value.length;

    if (val === 10) {

      fetchYatraEnq(e.target.value)
      setNumber(e.target.value)
      setLoginPopup(true)
      // handleSendOtp(number)
      setShow(false);
    }
    else {

      // setAllDisabled(false)
      setPartPay('fullpay')
      setLeftAmount(0)
      // reset({
      //   name: user.name,
      //   email: user.email,
      //   phone: user.phone,
      //   location: "",
      //   age: "",
      //   gender:"",
      //   preacherName:"",
      // });
      setYatraid('')
      setPartPay('')
      // setLeftAmount(res[0].RemainingAmount)

      setLeftAmount('');

      fetchSetdays('')
      fetchSetname('')
      //fetchSetadults(res[0].noOfAccompanyingPeople)
      fetchSetadults('')
      fetchSetstay('')

      fetchSetChild('')
      fetchSetRooms('')

      fetchSetOccupancy('')
      fetchSetTotalAmount('')
      fetchSetPreacherName('');
      fetchSetPaidAmount('')
      reset({
        name: '',
        email: '',
        location: '',
        age: '',
        gender: '',
        preacherName: '',
      });

    }




  }

  const [otpSent, setOtpSent] = useState(false);
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpExp, setOtpExp] = useState(expiry);
  const [resendtimes, setResendTimes] = useState(0);
  const [showresendlink, setShowResendLink] = useState(false);
  const [showTimer, setShowTimer] = useState(true)
  const [btnDisabled, setBtnDisabled] = useState(false)

  function getTime(time) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }

  const handleSendOtp = async () => {

    let titleYatra = selectedYatra.title;
    let data = {
      number,
      titleYatra,
    };

    if (timer) {
      clearInterval(timer)
    }
    if (number.length >= 10) {
      try {
        setBtnDisabled(true)
        const res = await ApiService.sendOtpYatra({ number, titleYatra });
        notification("success", res.message);
        setBtnDisabled(false)
        timer = setInterval(() => {
          setOtpExp(prev => prev - 1)
        }, 1000)

        setOtpSent(true);
      } catch (err) {
        console.log(err);
        if (err.response.status === 400) {
          notification('warning', err.response.data.message)
          setAllDisabled(false);
          setShow(true);
        } else {
          notification('error', err.response.data.message)
        }
        setBtnDisabled(false)
      }
    } else {
      notification("warning", 'Please Enter Valid Number');
      setBtnDisabled(false)
    }
  };


  const handleLogin = async () => {

    if (otp.length >= 6) {
      try {
        let titleYatra = selectedYatra.title;
        let data = {
          number,
          otp,
          titleYatra,
        };

        // ///ICS START///
        // const response = await ApiService.getIcsData(number)
        // // const url = process.env.REACT_APP_ICS_URL
        // // // const url = 'http://localhost:8080/ics/api/actionHandler'
        // // const response = await axios.get(url,{
        // //     params:{
        // //         cn:'donation',
        // //         an:'getICSids', //or getDonations
        // //         phone: number,
        // //         mode: 'DETAILED'    //DETAILED or DETAILED_WITH_DONATIONS or blank
        // //     },
        // //     withCredentials: true,
        // //     auth: {
        // //    username: process.env.REACT_APP_ICS_USERNAME,
        // //    password: process.env.REACT_APP_ICS_PASSWORD
        // // }
        // // });

        // console.log(response.data[0]);
        // localStorage.setItem('ics_id', response.data[0].id);
        // localStorage.setItem('ics_name', response.data[0].name);
        // localStorage.setItem('ics_number', response.data[0].number);

        ///ICS  END///

        const res = await ApiService.loginUserYatra(data);
        // console.log(res)
        localStorage.setItem(`token`, res.token);
        setToken(res.token);
        setLoginPopup(false);
        setShow(true);
        notification("success", res.message);
        setOtpSent(false);
        reset({
          phone: number,
        });
        setOtp('')
      } catch (err) {
        // console.log(err);
        notification("error", err.response.data.message);

      }
    } else {
      notification("warning", 'Please Enter Valid OTP');

    }
  };


  function setCookie(name, value) {
    let expires = "";
    let date = new Date();
    date.setTime(date.getTime() + (1 * 60 * 1000));
    expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const resendOtp = () => {
    setShowResendLink(false)

    if (getCookie("limitReached2")) {
      notification('warning', 'Resend OTP limit has been reached!')
      setResendTimes(0)
      setShowTimer(false)
    } else {
      if (resendtimes < 2) {
        setOtpExp(expiry)
        handleSendOtp()
        setResendTimes(prev => prev + 1)
      } else {
        setCookie("limitReached2", true)
        notification('warning', 'Resend OTP limit has been reached!')
        setShowTimer(false)
      }
    }
  }


  const handleHideModal = () => {
    setLoginPopup(false);
    setAllDisabled(false)
  };


  const handleYatraLogin = (yatra) => {
    setSelectedyatra(yatra);
    setShow(true);
    setAllDisabled(true)
  };


  useEffect(() => {
    if (otpExp === 0) {
      setShowResendLink(true)
      clearInterval(timer)
      setOtpExp(expiry)
    }
  }, [otpExp, expiry])


  return (
    <>
      <MetaData title="Yatras - ISKCON of Bhiwandi" />
      <PageHeader title="Yatras" imgSrc="/images/page-header/03.webp" />
      <AboutYatras />


      <section className="vrindavan-card">
        <div className="container">
          {

            !(Yatras.length > 0) ? <YatrasCardWrap /> : Yatras.map((Yatra, index) => {
              return (
                <>
                  {

                    Yatra.status === true ?
                      <div key={index} className="vrindavan-wrap mt-4">
                        <div className="row">
                          <div className="col-lg-5">
                            <figure className="yatra-fig">
                              <img src={`${process.env.REACT_APP_S3_URL}/uploads/yatra/${Yatra?.image}`} alt="" />
                            </figure>
                          </div>
                          <div className="col-lg-7">
                            <div className="heading my-auto">
                              <div className="mb-md-2 mt-md-0 mt-4">
                                <h2 className="head-2">{Yatra?.title} </h2>
                                {/* <h2 className="head-1">{Yatra?.title}</h2> */}
                              </div>
                              <div className="date-div mb-md-2">
                                <figure className="date-up">
                                  <img src="/images/yatras/up.svg" alt="" />
                                </figure>
                                <h5>{
                                  moment(Yatra.startDate).format('DD/MM/YYYY') === moment(Yatra.endDate).format('DD/MM/YYYY') ?
                                    moment(Yatra.startDate).format('dddd, D MMMM YYYY') :
                                    moment(Yatra.startDate).format('dddd, D MMMM') + " - " + moment(Yatra.endDate).format('dddd, D MMMM YYYY')
                                }</h5>
                                <figure className="date-down">
                                  <img src="/images/yatras/down.svg" alt="" />
                                </figure>
                              </div>
                              <div>
                                <p>
                                  {Yatra?.description}
                                </p>
                                <p className="my-1">
                                  Join us for the {Yatra?.title}
                                </p>
                                <p className="my-1">

                                  Between {
                                    moment(Yatra.startDate).format('DD/MM/YYYY') === moment(Yatra.endDate).format('DD/MM/YYYY') ?
                                      moment(Yatra.startDate).format('dddd, D MMMM YYYY') :
                                      moment(Yatra.startDate).format('dddd, D MMMM') + " - " + moment(Yatra.endDate).format('dddd, D MMMM YYYY')
                                  }
                                </p>

                              </div>

                              <>
                                {
                                  Yatra.androidCalenderLink === '1' ? <button onClick={() => handlePreRegister({ minPrice: Yatra?.minAmount, title: Yatra?.title, image: `${process.env.REACT_APP_S3_URL}/uploads/yatra/${Yatra?.image}`, })} className="custom-btn-cls box-hover my-4" style={{ width: '361px' }}>
                                    REGISTER
                                  </button> :
                                    <button className="custom-btn-cls box-hover my-4" style={{ width: '275px' }}>Registration will open soon...</button>
                                }
                                {/* {
                                  Yatra.androidCalenderLink === '1' ?
                                    <button onClick={() => handleYatraLogin({ minPrice: Yatra?.minAmount, title: Yatra?.title, image: `${process.env.REACT_APP_S3_URL}/uploads/yatra/${Yatra?.image}`, })} className="custom-btn-cls box-hover my-4" style={{ width: '361px' }}>
                                      If already registered, pls login to make the payment
                                    </button> : ''} */}
                              </>

                            </div>
                          </div>
                        </div>
                      </div>

                      : ''
                  }
                </>
              )
            })
          }
        </div>
      </section>

      {/* <button className="login-msg" onClick={() => setLoginPopup(true)}>
        <span>Login</span>

      </button> */}
      {/*       
      <section className="yatras-wrap">
        <div className="container">
          <div className="yatras-wrap">
            <div className="row my-5">
            {

!(Yatras.length > 0) ? <YatrasCardWrap />:Yatras.map((Yatra, index) => {
  return (
    <>
     {
                <div key={index} className="col-xl-3 col-lg-4 col-md-6 mb-5">
                  <div className="yatra-card">
                    <div className="yatra-top">
                      <figure>
                        <img src={`${process.env.REACT_APP_S3_URL}/uploads/yatra/${Yatra?.image}`} alt="" />
                      </figure>
                      <h5 className="text-uppercase">
                      {Yatra?.title}
                      </h5>
                      {Yatra.androidCalenderLink === '1'?
                     ''
                     :
                     <p> To be Announced.</p>
                     }
                     
                    </div>
                    <div className="book-btn">
                      <button
                        onClick={() => handlePreRegister(Yatra)}
                        className="box-hover"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        PRE-REGISTER NOW{" "}
                      </button>
                    </div>
                  </div>
                </div>
             }
             </>
             )
             })
             }
            </div>
          </div>
        </div>
      </section> */}

      {/* vrindavanDham1 */}
      <Modal centered show={show} onHide={handleModalHide}>
        <div className="modal-wrap ">
          <button className="btn close-svg" onClick={handleModalHide}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>

          <div className="row">
            {/* <div className="col-md-5 d-sm-block d-none">
              <figure>
                <img src={selectedYatra?.image} alt={selectedYatra?.title} />
              </figure>
            </div> */}
            <div className="col-md-12">
              <div className="pop-up-form">
                <div className="pop-up-top">
                  <div className="heading">
                    <h2 className="head-2">
                      {selectedYatra?.title.split("|")[0]}
                    </h2>
                    <h2 className="head-1">
                      {selectedYatra?.title.split("|")[1]}
                    </h2>
                  </div>
                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>

                <p>{selectedYatra?.description}</p>



                <form
                  action=""
                  className="mt-3 mt-sm-0"
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <div className="row">
                    {allDisabled == true ? '' :
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            id="name"
                            onKeyUpCapture={handleInputChange}
                            disabled={allDisabled}
                            {...register("name", { required: true })}
                            onKeyPress={(event) => {
                              if (/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {errors.name?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>}
                    {allDisabled == true ? '' :
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            id="email"
                            disabled={allDisabled}
                            {...register("email", {
                              required: true,
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Please enter a valid email",
                              },
                            })}
                          />
                          {errors.email?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                          {errors.email && (
                            <small className="text-danger error">
                              {errors.email.message}
                            </small>
                          )}
                        </div>
                      </div>
                    }
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="phone">Phone</label>
                        <input
                          type="text"
                          id="phone"
                          onKeyUp={allDisabled === false ? PhoneCheckk : PhoneCheck}
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("phone", {
                            required: true,
                            pattern: {
                              value: /^[6-9]\d*$/,
                              message: "Please provide valid phone number",
                            },
                            minLength: {
                              value: 10,
                              message: "Phone must be minimum 10 digit",
                            },
                            maxLength: {
                              value: 10,
                              message: "Phone must be maximum 10 digit",
                            },
                          })}
                        />
                        {errors.phone?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                        {errors.phone && (
                          <small className="error">
                            {errors.phone.message}
                          </small>
                        )}
                      </div>
                    </div>
                    {allDisabled == true ? '' :
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="location">City</label>
                          {/* <select id="location" {...register("location",{required:true})}>
                                                <option value="">Select location</option>
                                                <option value="Bhiwandi">Bhiwandi</option>
                                                <option value="Thane">Thane</option>
                                                <option  value="Mumbai">Mumbai</option>
                                                </select> */}
                          <input
                            type="text"
                            id="location"
                            disabled={allDisabled}
                            {...register("location", { required: true })}
                          />
                          {errors.location?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>}
                  </div>
                  <div className="row">
                    {/* {selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ? '' : 
                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="">Age</label>
                        <input
                          type="text"
                          disabled={allDisabled}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("age", {
                            pattern: {
                              value: /^\d+$/,
                              message: "Age must be number.",
                            },
                          })}
                          required={selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ? false : true}
                        />
                        {errors.age?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                        {errors.age && (
                          <small className="error">{errors.age.message}</small>
                        )}
                      </div>
                    </div>
                   } */}
                    {allDisabled == true ? '' :
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="gender">Gender</label>
                          <select
                            id="gender"
                            disabled={allDisabled}
                            {...register("gender", { required: true })}
                          >
                            <option value="">Select a gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          {errors.gender?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>}
                  </div>
                  <div>
                    <div className="label-input-wrap">
                      <label htmlFor="preacherName">Preacher Name:</label>
                      <select id="preacherName" disabled={allDisabled} {...register("preacherName")}>
                        <option value="">{preacherName ? preacherName : 'Select a preacher'}</option>
                        <option value="Bhavamaya Nityananda Das">
                          Bhavamaya Nityananda Das
                        </option>
                        <option value="Chaitanya Avatar Das">
                          Chaitanya Avatar Das
                        </option>
                        <option value="Ishan Gaur Das">Ishan Gaur Das</option>
                        <option value="Hridaya Yajna Das">
                          Hridaya Yajna Das
                        </option>
                        <option value="Pramod Gopinath Das">
                          Pramod Gopinath Das
                        </option>
                        <option value="Anantha Achintya Das">
                         Anantha Achintya Das
                        </option>
                        <option value="Mahapati Ram Das">
                          Mahapati Ram Das
                        </option>
                        <option value="Narottam Priya Das">
                          Narottam Priya Das
                        </option>
                        <option value="Nitai Jivana Das">Nitai Jivana Das</option>
                        <option value="Nitai Madhava Das">
                          Nitai Madhava Das
                        </option>
                        <option value="Radhakant Das">Radhakant Das</option>
                        <option value="Shantivardhan Das">
                          Shantivardhan Das
                        </option>
                        <option value="Sitaram Das">Sitaram Das</option>
                        <option value="Sri Sudama Das">Sri Sudama Das</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>

                  </div>
                  {allDisabled == true ?
                    <>
                      <div className="row mt-3">
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>Name:</label>
                            {fetch_name}
                          </div>
                        </div>
                        {fetch_day ?
                          <div className="col-sm-6">
                            <div className="label-input-wrap">
                              <label>No. Of Days:</label>
                              {fetch_day}
                            </div>
                          </div> : ''}
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>No. Of Adults:</label>
                            {fetch_adult}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>No. Of Children:</label>
                            {fetch_child}
                          </div>
                        </div>
                        {/* <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>No. Of Rooms:</label>
                            {fetch_rooms}
                          </div>
                        </div> */}
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>Type Of Stay:</label>
                            {fetch_stay}
                          </div>
                        </div>
                        {/* <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>Sharing:</label>
                            {fetch_occupancy === 'Personal' ? 'Self (Family)' : fetch_occupancy === 'Double' ? 'Twin sharing' :
                              fetch_occupancy === 'Tripple' ? 'Tripple sharing' : fetch_occupancy === 'Quadruple' ? 'Quadruple sharing' : ''}
                          </div>
                        </div> */}
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>Food and Local Transport Charges:</label>
                            ₹{FoodAndTran}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>Accomodation Charges:</label>
                            ₹{Accomodation}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>Total Amount Paid:</label>
                            ₹{fetch_totalamount}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>Booking Amount Paid:</label>
                            ₹{fetch_paidamount}
                          </div>
                        </div>
                        <div className="label-input-wrap">
                          <label>
                            Balance Amount Payable: {Accomodation ? '₹' + leftamount : '--STAUS AWAITED'}
                          </label>
                        </div>
                      </div>



                    </>

                    :
                    <>
                      <div>

                        <div className="label-input-wrap">
                          <label htmlFor="noOfDays">
                            {selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ? 'Please choose your room preference' : 'Stay:'}
                          </label>
                          <select
                            id="stay"
                            disabled={allDisabled}
                            onChange={handleStay}
                            //defaultValue={}
                            required>
                            <option value="">Select </option>
                            {selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ?
                            <>
                            <option value="1200">
                             AC
                            </option>
                            <option value="1000">
                             NON AC
                            </option>
                            </>
                            :
                            <option value="0">
                              Independent
                            </option>}
                          </select>
                          <small> {selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ? '* We will get in touch with you regarding confirmation of your room.' : ''}</small>
                        </div>
                        {disabledBtnn === true ? '' :
                          <>
                            <div className="label-input-wrap">
                              <label htmlFor="Occupancy">Type of Sharing:</label>
                              <select value={Occupancy} disabled={allDisabled} onChange={handleOccupancy} id="Occupancy">
                                <option value="">Select sharing</option>
                                <option value="Personal">
                                  Self (Family)
                                </option>
                                <option value="Double">
                                  Twin sharing
                                </option>
                                <option value="Tripple">
                                  Tripple sharing
                                </option>
                                {/* <option value="Quadruple">
                              Quadruple sharing
                            </option> */}
                              </select>
                            </div>
                            <div className="label-input-wrap">
                              <label htmlFor="noOfDays">
                                No of Rooms:
                              </label>
                              <select
                                id="noOfRooms"
                                disabled={allDisabled}
                                onChange={handleNoOfrooms}
                                value={noOfrooms}
                                required>
                                <option value="">Select no. of rooms</option>
                                {Array.from(Array(4).keys(), (val) => val + 1).map((val) => (
                                  <option key={val} value={val}>
                                    {val}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </>
                        }

                        {selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ? '' :
                          <div className="label-input-wrap">
                            <label htmlFor="noOfDays">
                              No of Days:
                            </label>
                            <select
                              id="noOfDays"
                              disabled={allDisabled}
                              onChange={handleDays}
                              value={noOfDays}
                              required={selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ? false : true}
                            >
                              <option value="">Select Days</option>
                              {Array.from(Array(selectedYatra?.title.split("|")[0] === 'Dakor Dham Yatra' ? 2 : 10).keys(), (val) => val + 1).map((val) => (
                                <option key={val} value={val}>
                                  {val}
                                </option>
                              ))}
                            </select>
                          </div>
                        }


                        {/* <div className="label-input-wrap">
                      <label htmlFor="Occupancy">Occupancy:</label>
                      <select value={Occupancy}  disabled={allDisabled} onChange={handleOccupancy} id="Occupancy">
                        <option value="0">Select Occupancy</option>
                        <option value="Personal">
                          Personal
                        </option>
                        <option value="Double">
                          Double
                        </option>
                        <option value="Tripple">
                          Tripple
                        </option>
                        <option value="Quadruple">
                          Quadruple
                        </option>
                      </select>
                    </div> */}
                        {/* {displayrooms > 0 ?
                    <div className="label-input-wrap">
                      <label htmlFor="noOfrooms">No. of Rooms:</label>
                      <select
                        id="noOfrooms"
                        onChange={handleNoOfrooms}
                        disabled={allDisabled}
                        value={noOfrooms}
                      required>
                         <option value="">Select no. of rooms</option>
                        {Array.from(Array(5).keys(), (val) => val + 1).map((val) => (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        ))}
                      </select>
                    </div>
                     :''} */}
                        <div className="row">
                          <div className="label-input-wrap col-12 mb-0">
                            <label htmlFor="">Add Family Members Participating in the Yatra</label>
                          </div>
                          <div className="label-input-wrap col-6">
                            {/* <div className="form-part">
                              <label htmlFor="relativeName">Name </label>
                              <input type="text" placeholder="Name of the person" {...register(`relativeName`)}></input>
                            </div> */}
                            <button type="button" className="custom-btn-cls add-relative-btn"
                              onClick={() => {
                                append({ inputName: "relativeName" });
                              }}>
                              <b style={{ fontSize: '20px' }}>+</b>  Add  Adults ( Above 9 years)
                            </button>
                            <div className="label-input-wrap">
                              <div className="row">
                                <div className="col-8 form-part">
                                  <label>Adult Name</label>
                                  <input
                                    type="text"
                                    defaultValue={inputValue}
                                    placeholder="Name of the person"
                                    {...register(`relatives.0.relativeName`)}
                                  />
                                </div>
                              </div>
                            </div>

                            {
                              fields.map((item, index) => {
                                return (<>{item.inputName == "relativeName" &&
                                  <div className="row" >

                                    <div className="col-8 form-part" key={item.id}>
                                      <label htmlFor="relativeName">Adult Name  </label>
                                      <input type="text" placeholder="Name of the person" {...register(`relatives.${index}.relativeName`)}></input>
                                    </div>

                                    <div className="col-lg-1 mb-1 mb-lg-0">
                                      <button type="button" onClick={() => remove(index)} className="form-close-btn"><MdClose /></button>
                                    </div>
                                  </div>
                                }</>)
                              })
                            }
                          </div>

                          {/* {displaychild > 0 ? */}


                          <div className="label-input-wrap col-6">
                            {/* <div className="form-part">
                              <label htmlFor="relativeName">Name </label>
                              <input type="text" placeholder="Name of the person" {...register(`relativeName`)}></input>
                            </div> */}
                            <button type="button" className="custom-btn-cls add-relative-btn"
                              onClick={() => {
                                append({ inputName: "children" });
                              }}>
                              <b style={{ fontSize: '20px' }}>+</b> Add Children ( Below 9 years)
                            </button>
                            <div className="label-input-wrap">
                              <div className="row">
                                <div className="col-8 form-part">
                                  <label>Children Name</label>
                                  <input
                                    type="text"
                                    placeholder="Name of the Child"
                                    {...register(`relatives.1.children`)}
                                  />
                                </div>
                              </div>
                            </div>
                            {
                              fields.map((item, index) => {
                                return (<>{item.inputName == "children" &&
                                  <div className="row" >

                                    <div className="col-8 form-part" key={item.id}>
                                      <label htmlFor="Name">Children Name   </label>
                                      <input type="text" placeholder="Name of the  Childreen" {...register(`relatives.${index}.Name`)}></input>
                                    </div>

                                    <div className="col-lg-1 mb-1 mb-lg-0">
                                      <button type="button" onClick={() => remove(index)} className="form-close-btn"><MdClose /></button>
                                    </div>
                                  </div>
                                }</>
                                )
                              })
                            }
                          </div>
                          {/* : ''} */}
                          {/* Default Adult Field */}

                          {/* Default Child Field */}


                          {/* <div className="label-input-wrap col-6">
                            <label htmlFor="noOfAccompanyingPeople">
                              Adults ( Above 9 years):
                            </label>
                            <select
                              id="noOfAccompanyingPeople"
                              onChange={handlePeople}
                              disabled={allDisabled}
                              value={People}
                              required>
                              <option value="">Select no. people</option>
                              {Array.from(Array(21).keys(), (val) => val + 1).map((val) => (
                                <option key={val} value={val}>
                                  {val}
                                </option>
                              ))}
                            </select>
                          </div> */}

                        </div>
                        {/* <div className="row">
                     <div >
                       <input type="radio" value="fullpay" onChange={handelPartPay} checked={part_pay === 'fullpay'} name="handeFullPay" /> Full Payment&nbsp;&nbsp;&nbsp;&nbsp;
                       <input type="radio" value="partpay" onChange={handelPartPay} checked={part_pay === 'partpay'} name="handelPartPay" /> Part Payment
                     </div>
                   </div> */}
                        {/* {part_pay === 'fullpay' ?
                     '' :
                     <div className="label-input-wrap mt-2">
                       <label htmlFor="partPayment">
                         Enter Amount
                       </label>
                       <input
                         id="partPayment"
                         readOnly={allDisabled}
                         {...register("partPayment")}
                         type="text"
                         placeholder="Enter Amount"
                         onKeyPress={(event) => {
                           if (!/[0-9]/.test(event.key)) {
                             event.preventDefault();
                           }
                         }}

                         onKeyUp={handleMinPriceKeyUp}

                         required={part_pay === 'fullpay' ? false : true}
                       />

                       <small className="error">{errorMinPrice}</small>

                     </div>} */}
                        {/* <div className="label-input-wrap">
                    <p>
                        Accomodation charges:  ₹{accomodation} <br></br>
                     
                        Prasadam and local transport charges:  ₹{(food  + Childcost)} 
                    </p>
                        <div className="label-input-wrap">
                          <label>
                        Total Price: ₹ {totalPrice > 0 ? totalPrice : '0'}
                        </label>
                      </div>

                    </div> */}
                        <div className="label-input-wrap">
                          <label htmlFor="childPeople">
                            Infant ( Below 2 years)
                          </label>
                          <select
                            id="childPeople"
                            disabled={allDisabled}
                            onChange={handleChild}
                            value={noofchild}
                          >
                            <option value="">Select no. infant</option>
                            {Array.from(Array(5).keys(), (val) => val + 0).map((val) => (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            ))}
                          </select>

                        </div>

                        <p>* Non Refundable Registration Charges <b>Rs.1000</b></p>
                      </div>
                    </>}
                  <div className="col-12">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      ref={recaptchaRef}
                      size="invisible"
                    />
                  </div>
                  {allDisabled == true && leftamount == 0 ? '' :
                    <div className="pop-form-btn">
                      <button className="box-hover" disabled={disabledBtn} >
                        {allDisabled == true ? 'Pay Your Balance Amount Rs. ' + leftamount : ' Register and Pay Booking Amount of Rs. 1000'}

                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>
                      </button>
                    </div>
                  }
                  {/* <p className="mb-0 mt-3">
                    <strong>Note:-</strong> <br></br>
                   - Please register your interest as seats are limited.<br></br>
                   - Yatra cost per day Rs.1000 and  Room cost per day Rs.2200.
                  </p> */}

                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal centered show={loginPopup} onHide={handleHideModal}>
        <div className="auth-modal">
          <button className="btn close-svg" onClick={handleHideModal}>
            <MdOutlineClose />
          </button>
          <div className="row align-items-center">
            <div className="col-md-6 d-sm-block d-none pe-md-0">
              <div className="popup-left-side">
                <figure className="bg_img">
                  <img src="/images/common/bg-line.jpg" alt="" />
                </figure>
                <div className="left-content">
                  <h4>Login</h4>
                  <div className="main-content">
                    <h3>
                      Log-in to save your details, view donation history and <span> download 80G certificate </span> conveniently.
                    </h3>
                  </div>
                </div>
                <figure className="popup-img">
                  <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-md-6 ps-md-0">
              {otpSent ? (
                <div className="pop-up-form">
                  <div className="pop-up-top">
                    <div className="heading">
                      <h2 className="head-2">OTP Verification</h2>
                    </div>
                  </div>
                  <p>Enter the OTP sent to +91 {number}</p>
                  <form action="" className="mt-3 mt-sm-0">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <div className="label-input-wrap">
                          <input
                            type="tel"
                            id="otp"
                            name="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            minLength={6}
                            maxLength={6}
                            placeholder="Enter OTP"
                          />
                        </div>
                        {
                          (!showresendlink && showTimer) &&
                          <div className="time-wrap">
                            <p className="mb-0">Resend OTP in</p>
                            <span className="timer_span d-inline-block">{getTime(otpExp)}s</span>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="pop-form-btn">
                      <button
                        className="box-hover"
                        type="button"
                        onClick={handleLogin}
                      >
                        Verify
                      </button>
                    </div>
                    {
                      showresendlink &&
                      <div className="time-wrap mt-4">
                        <p className="mb-0">Not yet received OTP?</p>
                        <button onClick={resendOtp} type='button'>Resend OTP</button>
                      </div>
                    }
                  </form>
                </div>
              ) : (
                <div className="pop-up-form">
                  <div className="pop-up-top">
                    <div className="heading">
                      <h2 className="head-2">Login</h2>
                    </div>
                  </div>
                  <p>We will send you an OTP on this mobile number.</p>
                  <form action="" className="mt-3 mt-sm-0">
                    <div className="row align-items-center m-0">
                      <div className="col-12 px-0">
                        <div className="label-input-wrap">
                          <input
                            type="tel"
                            id="number"
                            name="number"
                            placeholder="Enter Mobile Number"
                            defaultValue={number}
                            readOnly
                            required
                            onChange={(e) => setNumber(e.target.value)}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            minLength={10}
                            maxLength={10}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`pop-form-btn ${btnDisabled ? 'disabled' : ''}`}>
                      <button
                        className="box-hover"
                        type="button"
                        onClick={handleSendOtp}
                        disabled={btnDisabled}
                      >
                        Get OTP
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <GoToTop />
    </>
  );
};

export default Yatras;