import moment from 'moment/moment'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ApiService from '../api/ApiService'
import PageHeader from '../components/common/PageHeader'
import AppContext from '../context/AppContext'
import MetaData from '../helpers/MetaData'
import notification from '../helpers/notification'

const ShopThankYou = () => {
    const { fetchUser, user } = useContext(AppContext)
    const { id } = useParams()

    const [purchaseDetails, setPurchaseDetails] = useState(null)
    const navigate = useNavigate();

    const fetchPurchaseDetails = async () => {
        try {

            const res = await ApiService.fetchPurchaseDetails(id);
            setPurchaseDetails(res)

        } catch (error) {
            console.log(error)
            notification('error', 'Something went wrong!')
        }
    }

    useEffect(() => {
        fetchPurchaseDetails()
        if (user) {
            fetchUser()
        }
    }, [])

    return (
        <>
            <MetaData title="Thank You - ISKCON of Bhiwandi" />
            <PageHeader title="Thank You" imgSrc="/images/page-header/25.jpg" />
            <section className="thank-you-container">
                <div className="container">
                    {
                        purchaseDetails?.paymentStatus === 'success' &&
                        <div className="row">
                            <div className="col-lg-6 pe-lg-5">
                                <div className="heading text-start">
                                    <h2 className="head-1">
                                        Thank You!
                                    </h2>
                                    <p className="mt-4">Dear {purchaseDetails.name} Ji,</p>
                                    <p>
                                        Thank You for your purchase. May the grace of Krishna flow in to your life and may you, for all times, be in His refuge.
                                    </p>
                                    <p>
                                        As a token of our appreciation we will be happy to offer collective prayers in your name on your special occasions like your Birthday, Marriage Anniversary, Parent's and Children's birthdays on any other. Kindly share details in the form here.
                                    </p>
                                    <p className='mt-0'>
                                        Hare Krishna.
                                    </p>
                                </div>
                                {/* <div className="order-summary-wrap mt-5 mb-4">
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                                        <div className="order-summary-card">
                                            <h4>Order Id</h4>
                                            <p>{purchaseDetails?.orderId}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                                        <div className="order-summary-card _second">
                                            <h4>Date</h4>
                                            <p>{moment(purchaseDetails?.createdAt).format('DD-MM-YYYY')}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-4 mb-sm-0">
                                        <div className="order-summary-card">
                                            <h4>Total</h4>
                                            <p>{purchaseDetails?.totalAmount?.toLocaleString('en-IN', {
                                                    maximumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'INR'
                                            })}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="order-summary-card border-0">
                                            <h4>Payment Method</h4>
                                            <p className='text-uppercase'>{purchaseDetails?.paymentMethod}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                            <div className="col-lg-6">
                                <div className="order-details-wrap my-5">
                                    <div className="heading text-start">
                                        <h2 className='head-2 text-uppercase'>order details</h2>
                                    </div>
                                    <div className="details-table mb-4">
                                        <div className="details-card">
                                            <div className="row">
                                                <div className="col-6">
                                                    <h5>PRODUCT</h5>
                                                </div>
                                                <div className="col-6">
                                                    <h5 className='last'>TOTAL</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="details-card">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p>Order Id</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className='last'>#{purchaseDetails?.serialNumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="details-card">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p>Qty</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className='last'>#{purchaseDetails?.count}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="details-card">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p>{purchaseDetails?.productName}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className='last'>{purchaseDetails?.totalAmount?.toLocaleString('en-IN', {
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="details-card">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p>Shipping Address:</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className='last'>{purchaseDetails?.address}&nbsp;-&nbsp;{purchaseDetails?.pin}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="details-card">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p>Payment Method:</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className='last text-uppercase'>{purchaseDetails?.paymentMethod}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="details-card">
                                            <div className="row">
                                                <div className="col-6">
                                                    <h5>Total</h5>
                                                </div>
                                                <div className="col-6">
                                                    <h5 className='last'>
                                                        {purchaseDetails?.totalAmount?.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 2,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-sm-4">
                                <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                                    <Link to="/join-family" className='ms-0 custom-btn-cls box-hover text-uppercase ms-0'>
                                        Join the family of lord krishna
                                    </Link>
                                    {
                                        user &&
                                        <Link to="/dashboard" className='custom-btn-cls box-hover text-uppercase ms-0 ms-sm-1'>
                                            Go To Dashboard
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        purchaseDetails?.paymentStatus === 'pending' &&
                        <div className="row align-items-center">
                            <div className="col-8 mx-auto">
                                <div className="thank-you-text text-center">
                                    <div className="heading text-center">
                                        <h2 className="text-center head-1">Payment Pending <img src="/images/common/pending.gif" alt="" /> </h2>
                                        <p className="mt-4">Dear {purchaseDetails.name} Ji  ,</p>
                                        <p className='mt-0'>
                                            Your payment is being processed.
                                        </p>
                                        <p className='mt-0'>
                                            Hare Krishna.
                                        </p>
                                    </div>
                                    <Link to="/join-family" className='custom-btn-cls box-hover text-uppercase ms-0'>
                                        Join the family of lord krishna
                                    </Link>
                                    {
                                        user &&
                                        <Link to="/dashboard" className='custom-btn-cls box-hover text-uppercase'>
                                            Go To Home
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
        </>
    )
}

export default ShopThankYou