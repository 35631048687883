import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { Link } from 'react-router-dom'

const TermsAndCondition = () => {
    return ( 
        <>
         <MetaData title="Terms and Condition" />
         <section className="terms-and-condition-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">TERMS AND CONDITIONS</h2>
                                <p>The website www.iskconbhiwandi.org (The “SITE”) is fully owned by International Society of Krishna Consciousness (ISKCON), Plot No. 24A, Manasarovar, Bhiwandi, Thane, Maharashtra- 421302.</p>
                                <p>Your use of the Site is governed by the policies, terms, and conditions set forth below. Please read the following information carefully. By using this Site or donating through the site, you indicate your acceptance of, and agreement to be bound by, the terms set forth below. If you do not agree to these terms and conditions, please do not use this Site.</p>
                                <p>These terms and conditions may be changed by ISKCON in the future. It is your responsibility as a user to periodically return to this page to review the terms and conditions for amendments. The amended terms shall take effect automatically the day they are posted on the site. Your continued use of the web site following any amendments will constitute agreement to such amendments. These Terms of Service were last revised on March 25th, 2022.</p>
                                <p>Your continued usage of the site, shall be in acceptance to the terms that may be updated in future with retrospective effect. In case you do not agree to any terms your sole remedy is to stop using the website.</p>
                                <div className="terms-and-condition-btn">
                                    <Link className="custom-btn-cls box-hover" to="/privacy-policy">Privacy Policy</Link>
                                    <Link className="custom-btn-cls box-hover" to="/refund-policy">Refund policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
         <GoToTop />
        </>
     );
}
 
export default TermsAndCondition;