import React, { useCallback, useEffect, useState, useRef } from "react";
import ApiService from "../api/ApiService";
import "../css/brithdaySlot.css";
import notification from "../helpers/notification";
import moment from "moment/moment";
import { Modal, ModalTitle } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import fileDownload from 'js-file-download';
import { HiOutlineArrowLeft, HiOutlineLogout, HiCloudDownload, HiEye, HiPencil, HiShieldCheck, HiOutlineCheck } from "react-icons/hi";
//import ReactHtmlTableToExcel from "react-html-table-to-excel";
// import * as XLSX from 'xlsx';





const ViewSlot = () => {



  const navigate = useNavigate();

  const [birthdaySlots, setBirthdaySlots] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [viewData, setViewData] = useState({});

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportFromDate, setExportFromDate] = useState("");
  const [exportToDate, setExportToDate] = useState("");
  const [filteredBirthdaySlots, setFilteredBirthdaySlots] = useState([]);
  const [downloadButton, setdownloadButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgstatus_b, setMsgstatus_b] = useState("");

  const [msgstatus_f, setMsgstatus_f] = useState("");
  const [msgstatus_r, setMsgstatus_r] = useState("");
  const [msgstatus_z, setMsgstatus_z] = useState("");

  console.log(msgstatus_z, msgstatus_r, msgstatus_f)
  const userRole = localStorage.getItem('birthday_bookby');

  if (userRole === 'cultivator') {
    navigate('/brithday_slot')
  }
  const login_check = localStorage.getItem(`birthday_token`);
  if (!login_check) {
    navigate('/birthday_login')
    notification("error", "Please login now !");
  }

  const timeSlotArray = [
    '10:30AM-10:45AM',
    '10:45AM-11:00AM',
    '11:00AM-11:15AM',
    '11:15AM-11:30AM',
    '5:00PM-5:15PM',
    '5:15PM-5:30PM',
    '5:30PM-5:45PM',
  ];
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [selectDateForFilter, setselectDateForFilter] = useState('');




  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.fetchBirthdaySlots();
        console.log("response", response);
        setBirthdaySlots(response.brithdaySlotBook);
        setFilteredBirthdaySlots(response.brithdaySlotBook);
      } catch (error) {
        console.error("Error fetching data:", error);

      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    console.log("birthday ", birthdaySlots);
    const filterData = () => {
      const filteredData = birthdaySlots.filter(birthday => {
        console.log("filterdate", selectDateForFilter, selectedTimeSlot)

        const isDateMatch = moment(birthday.selectedDate).isSame(moment(selectDateForFilter).format('DD-MM-YYYY'), 'day');
        // const isDateMatch = !selectDateForFilter || birthday.selectedDate === selectDateForFilter;
        const isTimeMatch = !selectedTimeSlot || birthday.selectedTime === selectedTimeSlot;
        return isDateMatch && isTimeMatch;
      });
      console.log("filteredData", filteredData);
      setFilteredBirthdaySlots(filteredData);
    };
    if (selectDateForFilter !== '' || selectedTimeSlot !== '') {
      console.log("safilter", selectDateForFilter, selectedTimeSlot)
      filterData();
    }
  }, [selectDateForFilter, selectedTimeSlot, birthdaySlots]);
  const openDialog = (data) => {
    setEditData(data);
    setIsDialogOpen(true);
  };
  const openViewModal = (data) => {
    setViewData(data);
    setIsViewModalOpen(true);
  };

  const openStatusModal = async (u_id) => {
    setIsStatusModalOpen(true);
    //alert(u_id);
    const response = await ApiService.fetchBirthdaySlots();
    console.log(response.brithdaySlotBook)
    const filtermsgstatus = response.brithdaySlotBook.filter((item) => item._id === u_id);
    setMsgstatus_b(filtermsgstatus[0].whatsforBooklink);
    setMsgstatus_f(filtermsgstatus[0].whatsappfeedback);
    setMsgstatus_r(filtermsgstatus[0].whatsappreminder);
    setMsgstatus_z(filtermsgstatus[0].whatsforZoomlink);
    console.log(filtermsgstatus)
  };

  const logOutbirthday = () => {
    localStorage.removeItem(`birthday_name`);
    localStorage.removeItem(`birthday_phone`);
    localStorage.removeItem('birthday_token');
    localStorage.removeItem('birthday_bookby');
    navigate('/birthday_login')
  };
  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setViewData({});
  };

  const closeStatusModal = () => {
    setIsStatusModalOpen(false);
    setMsgstatus_f("");
    setMsgstatus_r("");
    setMsgstatus_z("");
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setEditData({});
  };
  const openExportModal = () => {
    setIsExportModalOpen(true);
  };
  const closeExportModal = () => {
    setLoading(false)
    setdownloadButton(false);
    setIsExportModalOpen(false);
    setExportFromDate("");
    setExportToDate("");
  };

  const handleBackButtonClick = async () => {

    navigate('/brithday_slot');
  };

  const handleExportSubmit = async (data) => {
    data.preventDefault();
    console.log(exportFromDate);
    console.log(exportToDate);

    let AllData = {
      from: exportFromDate,
      to: exportToDate
    }

    setLoading(true)

    const res = await ApiService.downloadExcelPuja(AllData)

    setTimeout(() => {
      setdownloadButton(true);
      setLoading(false)
    }, 3000);

  };

  const handelDownloadFile = async (e) => {
    e.preventDefault();
    const startDate = moment(exportFromDate).format('DD-MM-YYYY');
    const endDate = moment(exportToDate).format('DD-MM-YYYY');

    let AllData = {
      from: exportFromDate,
      to: exportToDate
    }

    const res = await ApiService.downloadExcelPuja(AllData)

    fileDownload(res, `${'puja-data-' + startDate + '-' + endDate}.csv`);

    setTimeout(() => {
      setLoading(false)
      setdownloadButton(false);
    }, 1000);


  }

  const sendWhatsAppMessageForPooja = async () => {
    try {
      //Send Whatsapp Message Here For Slot Booking
      console.log("Selected Time Slot:", selectedTimeSlot);
      const dataToSend = {
        selectedTimeSlot: selectedTimeSlot,
        birthdaySlots: birthdaySlots.map((birthday) => ({
          donorname: birthday.donorname,
          phone: birthday.phone,
          selectedDate: birthday.selectedDate,
          selectedTime: birthday.selectedTime,
          whatsforZoomlink: birthday.whatsforZoomlink,



        })),
      };
      console.log("dataToSend", dataToSend)
      const res = await ApiService.sendZoomLinkForBirthdayPuja(dataToSend);
      notification("success", "Hare Krishna! Zoom Link Send.");
      reset();
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }

  };



  const sendWhatsAppReminderMessageForPooja = async () => {
    try {
      //Send Whatsapp Message Here For Slot Booking
      console.log("Selected Time Slot:", selectedTimeSlot);
      const dataToSend = {
        selectedTimeSlot: selectedTimeSlot,
        birthdaySlots: birthdaySlots.map((birthday) => ({
          donorname: birthday.donorname,
          phone: birthday.phone,
          selectedDate: birthday.selectedDate,
          selectedTime: birthday.selectedTime,
          whatsappreminder: birthday.whatsappreminder,


        })),
      };
      // console.log("dataToSend", dataToSend)
      const res = await ApiService.sendReminderForBirthdayPuja(dataToSend);
      notification("success", "Hare Krishna! FeedBack Link Send.");
      reset();
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }

  };




  const updateRow = async (updatedData) => {
    console.log("updatedData", updatedData, editData)
    try {

      const feedbacklink = `https://www.iskconbhiwandi.org/feedback/${editData._id}`;
      console.log("linkgenerate", feedbacklink);
      await ApiService.updateBirthdaySlot(editData._id, editData.icsid, updatedData, feedbacklink, editData);
      notification("success", "Hare Krishna! Feedback Form sent To Donor.");
      reset();

      const updatedSlots = birthdaySlots.map((birthday) =>
        birthday._id === editData._id ? { ...birthday, ...updatedData } : birthday
      );
      setBirthdaySlots(updatedSlots);


      closeDialog();
    } catch (error) {
      console.error("Error updating data:", error);

    }
  };

  return (
    <div className="container">
      <div className="brithdayview-header">
        <div className="heading p-3">
          <h4 className="head-1" style={{ fontSize: '28px' }}>BIRTHDAY PUJA SLOTS</h4>
        </div>
        {/* <h1 style={{ textAlign: 'center' }}>Birthday Puja Slots</h1> */}
        <div>
          <button className="box-hover text-white p-1" onClick={handleBackButtonClick} style={{ marginRight: '5px', borderRadius: '2px', border: '2px solid #363434' }}>
            <span>&nbsp;&nbsp;</span> <HiOutlineArrowLeft />  <span>&nbsp;&nbsp;</span>
          </button>

          <input
            type="date"
            id="selectDateForFilter"
            className="p-1"
            style={{ marginRight: '5px', border: '2px solid #363434' }}
            value={selectDateForFilter}
            onChange={(e) => setselectDateForFilter(e.target.value)}
            required
          />
          <select style={{ marginRight: '5px', border: '2px solid #363434', padding: '6px' }} onChange={(e) => setSelectedTimeSlot(e.target.value)} value={selectedTimeSlot}>
            <option value="">Select a time slot</option>
            {timeSlotArray.map((timeSlot, index) => (
              <option key={index} value={timeSlot}>
                {timeSlot}
              </option>
            ))}
          </select>
          {/* <button className="box-hover text-white" onClick={sendWhatsAppMessageForPooja} style={{ marginRight: '5px' }}>
            Send Zoom Link
          </button>
          <button className=" box-hover text-white" onClick={sendWhatsAppReminderMessageForPooja} style={{ marginRight: '5px' }}>Reminder</button> */}
          <button className="box-hover text-white p-1" onClick={openExportModal} style={{ marginRight: '5px', borderRadius: '2px', border: '2px solid #363434' }}>

            <span>&nbsp;</span> <HiCloudDownload />  <span>Export</span>
          </button>

          <button
            className="box-hover  text-white p-1"
            onClick={logOutbirthday}

            style={{ borderRadius: '2px', border: '2px solid #363434' }}
          >
            <span>&nbsp;</span> <HiOutlineLogout />  <span>Logout</span>
          </button>
        </div>

        {/* <button>
          <ReactHtmlTableToExcel
            id="test-table-xls-button"
            className="box-hover text-white"
            table="table-to-xls"
            filename="birthday_slots"
            sheet="tablexls"
            buttonText="Export to Excel"
          />
        </button> */}
      </div>
      <table className="table mt-1" style={{ border: '1px solid #363434' }}>
        <thead>
          <tr>
            <th>Donor ICS ID</th>
            <th>Donor Name</th>
            <th>Donor Mobile Number</th>
            <th>Slot Date</th>
            <th>Slot Time</th>
            <th>Booked By</th>
            <th>Status</th>
            <th>Remark</th>
            <th>Rating</th>
            <th>Feedback</th>
            <th>Action</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredBirthdaySlots && filteredBirthdaySlots.map((birthday) => (
            <tr key={birthday._id}>
              <td>{birthday.icsid}</td>
              <td>{birthday.donorname}</td>
              <td>{birthday.phone}</td>
              <td>{moment(birthday.selectedDate, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('DD-MM-YYYY')}</td>
              <td>{birthday.selectedTime}</td>
              <td>{birthday.bookername}</td>
              <td>{birthday.poojastatus}</td>
              <td>{birthday.remarks}</td>
              <td>{birthday.starValue}</td>
              <td> <button className="box-hover text-white" onClick={() => openViewModal(birthday)} style={{ border: '2px solid #363434', padding: '2px' }}>
                <span>&nbsp;</span> <HiEye />  <span>view&nbsp;</span>
              </button></td>
              <td>
                <button className=" box-hover text-white" onClick={() => openDialog(birthday)} style={{ border: '2px solid #363434', padding: '2px' }}>
                  <span>&nbsp;</span> <HiPencil />  <span>edit&nbsp;</span>


                </button>
              </td>
              <td>
                <button className=" box-hover text-white" onClick={() => openStatusModal(birthday._id)} style={{ border: '2px solid #363434', padding: '2px' }}>
                  <span>&nbsp;</span> <HiShieldCheck />  <span>Status&nbsp;</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>






      <Modal centered show={isExportModalOpen} >
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={closeExportModal}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">
            <div className="col-md-12">
              <div className="pop-up-form">
                <div className="pop-up-top">
                  <figure className="hare-krishna-logo-fig">
                    <img src={`/images/campaign/feedbackimg.jpeg`} alt="" />
                  </figure>
                  <h3>Birthday Slot Details Export Excel</h3>
                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>
                <form onSubmit={handleExportSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="label-input-wrap">
                        <label htmlFor="exportFromDate" className="imp">
                          From Date
                        </label>
                        <input
                          type="date"
                          id="exportFromDate"
                          value={exportFromDate}
                          onChange={(e) => setExportFromDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="label-input-wrap">
                        <label htmlFor="exportToDate" className="imp">
                          To Date
                        </label>
                        <input
                          type="date"
                          id="exportToDate"
                          value={exportToDate}
                          onChange={(e) => setExportToDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    {downloadButton ?

                      <button
                        className="box-hover custom-btn"
                        onClick={handelDownloadFile}
                      >
                        Download
                      </button>

                      :
                      <>


                        {loading ?
                          <button className='mb-0 custom-btn-cls box-hover px-4' >
                            Generating Data...
                            <div className="spinner-border text-light ms-2" role="status"></div>
                          </button>
                          :
                          <button
                            className="box-hover custom-btn"
                            type="submit"
                          >
                            Generate
                          </button>
                        }

                      </>
                    }

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>


      <Modal centered show={isViewModalOpen}>
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={closeViewModal}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">

            <div className="col-md-12">
              <div className="pop-up-form">

                <div className="pop-up-top">
                  <figure className="hare-krishna-logo-fig">
                    <img src={`/images/campaign/feedbackimg.jpeg`} alt="" />
                  </figure>
                  <h3>Donor Feedback Details</h3>

                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>

                <p>{viewData.donorFeedback}</p>


              </div>
            </div>
          </div>
        </div>
      </Modal>


      <Modal centered show={isStatusModalOpen}>
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={closeStatusModal}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">

            <div className="col-md-12">
              <div className="pop-up-form">

                <div className="pop-up-top">
                  <figure className="hare-krishna-logo-fig">
                    <img src={`/images/campaign/feedbackimg.jpeg`} alt="" />
                  </figure>
                  <h3>Message Status</h3>

                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>

                <table className="table mt-1" style={{ border: '1px solid #363434' }}>

                  <tbody>
                    <tr>
                      <th>Book Message </th>
                      <td>
                        <center><button className=" box-hover text-white" style={{ border: '2px solid #363434', padding: '2px', }}>
                          {msgstatus_b === "true" ?
                            <>
                              &nbsp;Pending&nbsp;
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 16 16"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
                                ></path>
                              </svg>
                            </>
                            :
                            <>
                              &nbsp;Sent&nbsp;<HiOutlineCheck />
                            </>
                          }
                        </button></center>
                      </td>
                    </tr>


                    <tr>
                      <th>Zoom Link </th>
                      <td>
                        <center><button className=" box-hover text-white" style={{ border: '2px solid #363434', padding: '2px', }}>
                          {msgstatus_z === "true" ?
                            <>
                              &nbsp;Pending&nbsp;
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 16 16"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
                                ></path>
                              </svg>
                            </>
                            :
                            <>
                              &nbsp;Sent&nbsp;<HiOutlineCheck />
                            </>
                          }
                        </button></center>
                      </td>
                    </tr>
                    <tr>
                      <th>Reminder</th>
                      <td>
                        <center><button className=" box-hover text-white" style={{ border: '2px solid #363434', padding: '2px', }}>
                          {msgstatus_r === "true" ?
                            <>
                              &nbsp;Pending&nbsp;
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 16 16"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
                                ></path>
                              </svg>
                            </>
                            :
                            <>
                              &nbsp;Sent&nbsp;<HiOutlineCheck />
                            </>
                          }
                        </button></center>
                      </td>
                    </tr>
                    <tr>
                      <th>Feedback</th>
                      <td>
                        <center><button className=" box-hover text-white" style={{ border: '2px solid #363434', padding: '2px', }}>
                          {msgstatus_f === "true" ?
                            <>&nbsp;Pending&nbsp;
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 16 16"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
                                ></path>
                              </svg>
                            </>
                            :
                            <>
                              &nbsp;Sent&nbsp;<HiOutlineCheck />
                            </>
                          }
                        </button></center>
                      </td>
                    </tr>
                  </tbody>
                </table>


              </div>
            </div>
          </div>
        </div>
      </Modal>



      <Modal centered show={isDialogOpen}>
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={closeDialog}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">
            <div className="col-md-5 d-sm-block d-none">
              <figure>
                <img
                  className="w-100"
                  src={`/images/campaign/feedbackimg.jpeg`}
                  alt="Campaign image"
                />
              </figure>
            </div>
            <div className="col-md-7">
              <div className="pop-up-form">

                <div className="pop-up-top">
                  <h2>Update Puja Details</h2>

                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>

                <form
                  action=""
                  className="mt-3 mt-sm-0"
                  onSubmit={handleSubmit(updateRow)}
                >


                  <div className="row">

                    <div className="col-md-6">
                      <div className="label-input-wrap">
                        <label htmlFor="poojastatus" className="imp">Status</label>
                        <select type="text"
                          id="poojastatus" {...register("poojastatus")}>
                          <option value="Done">Done</option>
                          <option value="Cancel">Cancel</option>
                        </select>
                        {errors.whoisbook?.type === "required" && (
                          <span> This is required </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="label-input-wrap">
                        <label htmlFor="remarks" className="imp">Remark</label>
                        <input
                          type="text"
                          id="remarks"
                          {...register("remarks", { required: true })}
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.remarks?.type === "required" && (
                          <span>Remark is required</span>
                        )}
                      </div>
                    </div>

                    <button className="box-hover custom-btn" type="submit">Sent Feedback Form</button>

                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ViewSlot;
